import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { logEvent, setCurrentScreen } from "./FirebaseUtils";

export const useConfirmCallback = (
  message: string,
  cb: (args: any) => void
) => {
  return useCallback(
    (...args) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        cb(args);
      }
    },
    [message, cb]
  );
};

export const useDocumentTitle = (title: string | undefined) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
};

export const useTargetBlank = (selector: string, deps: any[]) => {
  useEffect(() => {
    const anchors = document.querySelectorAll(selector);
    for (let i = 0; i < anchors.length; i++) {
      anchors[i].setAttribute("target", "_blank");
    }
  }, [selector, deps]);
};

export const useWindowSize = () => {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  // const debouncedSetWindowSize = useDebounce(setWindowSize, 1000);

  // @ts-ignore
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useDebounce = (value: any, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay]
  );

  return debouncedValue;
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const useKeydown = (key: number, action: any) => {
  useEffect(() => {
    function onKeydown(e: any) {
      if (e.which === key) {
        action();
      }
    }
    window.addEventListener("keydown", onKeydown);
    return () => window.removeEventListener("keydown", onKeydown);
  }, [action, key]);
};

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    setCurrentScreen(pagePath);
    logEvent("page_view", { pagePath });
  }, [location]);
};

export const useScripts = (urls: string[]) => {
  useEffect(() => {
    const scripts: HTMLScriptElement[] = [];

    urls.forEach((url) => {
      const script: HTMLScriptElement = document.createElement("script");

      script.src = url;
      script.async = true;

      document.body.appendChild(script);
      scripts.push(script);
    });

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, [urls]);
};

// const result = useAsync(() => fn(a), [a])
// const useAsync = <T>(fn: () => Promise<T>, deps: any[]) => {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<Error | undefined>();
//   const [res, setRes] = useState<T | undefined>();
//   useEffect(() => {
//      setLoading(true);
//      let cancel = false;
//      fn().then(res => {
//         if (cancel) return;
//         setLoading(false);
//         setRes(res)
//      }, error => {
//         if (cancel) return;
//         setLoading(false);
//         setError(error);
//      })
//      return () => {
//         cancel = true;
//      }
//   }, deps)
//   return {loading, error, res};
// }
