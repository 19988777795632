import React, { Suspense, lazy } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
// @ts-ignore
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import Message from "./Components/Message";
import { initFirebase } from "./FirebaseUtils";
import { useGoogleAnalytics } from "./Hooks";

const Home = lazy(() => import("./Routes/Home"));
const Decks = lazy(() => import("./Routes/Decks/Index"));
const Docs = lazy(() => import("./Routes/Docs/Index"));
const LogIn = lazy(() => import("./Routes/LogIn"));
const LogOut = lazy(() => import("./Routes/LogOut"));

export default () => {
  const { reduxConfig, store } = initFirebase();

  function Routes() {
    useGoogleAnalytics();

    return (
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/decks">
          <Decks />
        </Route>
        <Route path="/docs">
          <Docs />
        </Route>
        <Route path="/login">
          <LogIn />
        </Route>
        <Route path="/logout">
          <LogOut />
        </Route>
      </Switch>
    );
  }

  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={reduxConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <Router>
          <Suspense fallback={<Message>Loading...</Message>}>
            <Routes />
          </Suspense>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};
