import { useEffect, useState } from "react";
// @ts-ignore
import { isEmpty, isLoaded } from "react-redux-firebase";
import {
  DeckDBValue,
  ItemDBValue,
  CardDBValue,
  CardMap,
  CardTuple,
  ItemMap,
  ItemTuple,
  Deck,
  PolyModelInfo,
  Format,
} from "./reducer";

export const isProduction = process.env.NODE_ENV === "production";
export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const polyAPIKey = process.env.REACT_APP_POLY_API_KEY;
export const siteOwner = process.env.REACT_APP_SITE_OWNER;
export const siteName = process.env.REACT_APP_SITE_NAME;
export const siteURL = process.env.REACT_APP_SITE_URL;
export const lastReviewed = process.env.REACT_APP_SITE_LAST_REVIEWED;
export const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

// can't import from tailwind.js unfortunately...
export const sm = 640;
export const md = 768;
export const lg = 1024;
export const xl = 1280;

export const getModelIdFromUrl = (url: string) => {
  const parsedUrl: URL = new URL(url);
  const id: string = parsedUrl.pathname.split("/view/")[1];
  return id;
};

export const getPolyModelInfo = (url: string) => {
  return new Promise<PolyModelInfo>((resolve) => {
    const API_KEY = polyAPIKey;
    // get asset id from url
    const id = getModelIdFromUrl(url);
    const apiUrl = `https://poly.googleapis.com/v1/assets/${id}/?key=${API_KEY}`;
    const request = new XMLHttpRequest();
    request.open("GET", apiUrl, true);
    request.addEventListener("load", (event: any) => {
      const model = JSON.parse(event.target.response);
      resolve(model);
    });
    request.send(null);
  });
};

export const importItem = async (url: string): Promise<PolyModelInfo> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<PolyModelInfo>(async (resolve, reject) => {
    const deckImport = await getPolyModelInfo(url);

    if (
      !deckImport.formats.find((format) => {
        return format.formatType === "GLTF2" || format.formatType === "OBJ";
      })
    ) {
      // no GLTF2 or OBJ format available - that's all that is supported in A-Frame
      reject(
        new Error(
          "Sorry, the model cannot be imported. Only glTF2 and obj formats are supported."
        )
      );
    }

    deckImport.url = url;
    resolve(deckImport); // deckImport will have an error property if something went wrong
  });
};

// parses the deck, items, and cards into a single convenience object
export const useDeck = ({
  deck,
  items,
  cards,
  cb,
}: {
  deck: DeckDBValue;
  items: ItemDBValue[];
  cards: CardDBValue[];
  cb: (deck: Deck | null) => void;
}) => {
  useEffect(() => {
    if (isLoaded(deck) && isLoaded(items) && isLoaded(cards)) {
      if (isEmpty(deck)) {
        cb(null);
      } else {
        // parse cards and items into maps

        const cardsArray: CardDBValue[] = [...cards];

        const cardsMap: CardMap = new Map(
          cardsArray
            .sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            })
            .map((anno: CardDBValue) => [anno.id, { ...anno }] as CardTuple)
        );

        const itemsArray: ItemDBValue[] = [...items];
        const itemsMap: ItemMap = new Map(
          itemsArray.map(
            (item: ItemDBValue) => [item.id, { ...item }] as ItemTuple
          )
        );

        const returnVal: Deck = {
          ...deck,
          items: itemsMap,
          cards: cardsMap,
        };

        cb(returnVal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deck, items, cards]);
};

export const usePolyModelInfo = (url: string) => {
  const [modelInfo, setModelInfo] = useState<PolyModelInfo | null>(null);
  useEffect(() => {
    let cancel = false;
    getPolyModelInfo(url).then((res) => {
      if (cancel) return;
      setModelInfo(res);
    });
    return () => {
      cancel = true;
    };
  }, [url]);
  return modelInfo;
};

export const getGLTF = (modelInfo: PolyModelInfo) => {
  const format: Format | undefined = modelInfo.formats.find(
    (format) => format.formatType === "GLTF2"
  );
  const gltf = format?.root.url;
  return gltf;
};
