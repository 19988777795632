import firebase from "firebase/app";
import "firebase/functions";
import { isProduction } from "./DeckUtils";
import configureStore from "./store";
import { firebase as config, reduxFirebase as reduxConfig } from "./config";

let functions: firebase.functions.Functions;

export const initFirebase = () => {
  const initialState = window && window.__INITIAL_STATE__;
  const store = configureStore(initialState);

  if (!firebase.apps.length) {
    functions = firebase.initializeApp(config).functions("europe-west2");
  }

  return { reduxConfig, store };
};

export const timestamp = () => {
  return firebase.firestore.Timestamp.now();
};

export const getDeck = async (publicId: string) => {
  const fn = functions.httpsCallable("getDeck");
  return fn({ publicId });
};

export const getDeckWithoutItems = async (publicId: string) => {
  const fn = functions.httpsCallable("getDeckWithoutItems");
  return fn({ publicId });
};

export const getRandomWords = async (numWords: number) => {
  const fn = functions.httpsCallable("getRandomWords");
  return fn({ numWords });
};

export const setCurrentScreen = (screen: string) => {
  firebase.analytics().setCurrentScreen(screen);
};

export type AnalyticsEvent = "page_view";

// https://developers.google.com/gtagjs/reference/event
export const logEvent = (event: AnalyticsEvent, params: any) => {
  // console.log(event, params);
  if (isProduction) {
    firebase.analytics().logEvent(event as string, params);
  }
};
