import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

export type Timestamp = firebase.firestore.Timestamp;

export interface DeckDBValue {
  author: string;
  created: Timestamp;
  description: string;
  id: string;
  modified: Timestamp;
  publicId: string;
  rights: string;
  title: string;
  duplicatedFrom: string | null;
  vanityUrl: string;
}

export interface Deck extends DeckDBValue {
  items: ItemMap;
  cards: CardMap;
}

export type PublicDeckValue = Omit<DeckDBValue, "id">;

export interface PublicDeck extends PublicDeckValue {
  items: ItemValue[];
  cards: CardValue[];
}

export type ItemDBValue = {
  created: string;
  id: string;
  label: string | undefined;
  url: string;
  thumbnail: string | undefined;
};

export type ItemValue = Omit<ItemDBValue, "id">;
export type ItemTuple = [string, ItemValue];
export type ItemMap = Map<string, ItemValue>;

export type Orientation = {
  scale: number;
  xRotation: number;
  yRotation: number;
  zRotation: number;
  xPosition: number;
  yPosition: number;
  zPosition: number;
};

export type CardDBValue = {
  created: Timestamp;
  id: string;
  modified: Timestamp;
  orderIndex: number;
  url: string | undefined;
  comment: string | undefined;
  thumbnail: string | undefined;
  orientation: Orientation;
};

export type CardValue = Omit<CardDBValue, "id">;
export type CardTuple = [string, CardValue];
export type CardMap = Map<string, CardValue>;

export type CardEditorValue = Omit<
  CardValue,
  "id" | "created" | "modified" | "orderIndex" | "url" | "thumbnail"
>;

type Resource = {
  contentType: string;
  relativePath: string;
  url: string;
};

type FormatType = "GLTF2" | "FBX" | "TILT" | "OBJ";

export type Format = {
  formatComplexity: { triangleCount: string };
  formatType: FormatType;
  resources: Resource[];
  root: { contentType: string; relativePath: string; url: string };
};

export type PolyModelInfo = {
  error: {
    code: number;
    message: string;
    status: string;
  };
  url: string;
  authorName: string | null;
  createTime: string;
  displayName: string;
  formats: Format[];
  license: string;
  name: string;
  presentationParams: {
    orientingRotation: any;
    colorSpace: string;
    backgroundColor: string;
  };
  thumbnail: { relativePath: string; url: string; contentType: string };
  updateTime: string;
  visibility: string;
};

export type DeckImports = PolyModelInfo[];

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
